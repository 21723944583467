//import { $t } from 'o365-utils';
import {getFullTextSearchString,searchTextWithLeadingZeroes,searchTextWithoutLeadingZeroes} from './helpers.values.ts';
export default class FullText{
   
    fnc:string;
    
    column:string;
  
    constructor(pColumn:string,pSearchFunction:string){
        this.fnc = pSearchFunction;
        this.column = pColumn;
    }

    getFilterString(pSearchString:string){
        const leadingZeroesStr = searchTextWithLeadingZeroes(pSearchString, );
        const noLeadingZeroesStr = searchTextWithoutLeadingZeroes(pSearchString);
        const vReturn =  `FULLTEXT_SEARCH(${this.fnc},'${noLeadingZeroesStr}',${this.column})`;
        if(leadingZeroesStr?.length){
            return `(${vReturn} AND ${this._generateLeadingZeroesString(leadingZeroesStr)})`
       
        }

        return vReturn;
       
    }

    private _generateLeadingZeroesString(pString:Array<string>){
        return pString.map(x=> `[${this.column}] LIKE '%${x}%'`).join(" AND ");
    }

   

    

    
}